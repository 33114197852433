import * as React from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';

import { useQuery } from '@apollo/client';

import { Container } from '../../../layout/Container';
import { BackgroundLoad } from '../../../layout/BackgroundLoad';

import { Heading } from '../../../ui/text/Heading';
import { Paragraph } from '../../../ui/text/Paragraph';
import { BrokerListItem } from '../../../ui/broker/ListItem';
import { useFadeTransition } from '../../../../utils/hooks/useFadeTransition';
import NoMatch from '../../404Old';
import { Section } from '../../../layout/Section';
import { DEPARTMENT_EMPLOYEES_QUERY } from '../../../../../client/__graphql__/cluster/queries/department/employees';
import {
  DepartmentEmployeesQuery,
  DepartmentEmployeesQueryVariables
} from '../../../../__types__/generated/cluster';
import { ENV_CONFIG } from '../../../../__config__/env';

const OfficeBrokers: React.FC = () => {
  const params = useParams<{ urltag: string }>();
  const { data, loading } = useQuery<
    DepartmentEmployeesQuery,
    DepartmentEmployeesQueryVariables
  >(DEPARTMENT_EMPLOYEES_QUERY, {
    fetchPolicy: 'network-only',
    ssr: true,
    variables: {
      input: {
        alias: params.urltag,
        brandId: ENV_CONFIG?.BRAND_ID
      }
    }
  });

  const { fadeIn: pageFadeIn } = useFadeTransition({
    immidiate: !loading
  });

  if (!loading && (!data || !data.department)) {
    return <NoMatch />;
  }

  return (
    <>
      <Container
        style={{ ...pageFadeIn, minHeight: '100vh' }}
        className="padding-top"
      >
        <Section>
          <Heading tag="h1" center={true}>
            Meglere
          </Heading>
          <Paragraph center={true} style={{ marginBottom: '40px' }}>
            Dette er en presentasjon av våre meglere. Ta kontakt hvis du ønsker
            en E-takst (verdivurdering).
          </Paragraph>
          <BrokerList>
            {data?.department?.employees &&
              data?.department?.employees?.map((item, index: number) => (
                <BrokerListItem
                  key={`broker_${index}`}
                  name={item?.name}
                  title={item?.title}
                  office={data?.department?.name}
                  image={item?.image?.url}
                  path={
                    item?.employeeAlias
                      ? `https://privatmegleren.no/megler/${item?.employeeAlias}`
                      : ``
                  }
                />
              ))}
          </BrokerList>
        </Section>
      </Container>
      <BackgroundLoad
        showOnMobile={true}
        opacity={0.35}
        url="https://cdn.reeltime.no/pm_assets/img/bakgrunner/hjort_bakside2.jpg"
      />
    </>
  );
};

export default OfficeBrokers;

const BrokerList = styled.div`
  display: flex;
  flex-flow: column;
`;
